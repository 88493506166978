import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-2.svg"
import {LinkOutlined} from "@ant-design/icons";
import storia from "../../assets/images/sp/2/storia.jpg";
import salumi from "../../assets/images/sp/2/beretta.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Fratelli Beretta | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 2</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.fratelliberetta.com" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs:16, lg: 48}}>
                            <Col xs={24} lg={12} className="text-justify">
                                <h2>Passione per la qualità dal 1812</h2>
                                <p>L’impegno di otto generazioni per portare <span className="fw-bold">nelle case di tutto il mondo il gusto unico dei nobili salumi della tradizione italiana.</span><br/>
                                Questo il segreto di un’azienda che ha saputo innovarsi continuamente per rispondere alle
                                    diverse esigenze di un mercato e di un consumatore in continua evoluzione.</p>
                                <p>Una passione supportata dall’impegno della famiglia, dalle competenze e professionalità di
                                    tutti i propri collaboratori, una qualità continuamente ricercata che si può gustare in ogni
                                    singolo prodotto Beretta.</p>
                                <p>L’azienda investe da sempre nella produzione dei salumi DOP e IGP diventando così una delle
                                    più importanti e rappresentative realtà dell’industria alimentare italiana. Oggi Beretta è
                                    presente in quasi tutti i paesi europei ed extraeuropei e rappresenta una delle realtà più
                                    dinamiche e rilevanti nell’export dell’Italian Food.</p>
                            </Col>
                            <Col xs={24} lg={12}>
                                <img src={storia} alt="Fratelli Beretta" className="sp-img d-inline-block align-top mb-4 shadow rounded"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row align="top">
                            <Col xs={24} className="text-center">
                                <img src={salumi} alt="Fratelli Beretta" className="sp-img d-inline-block align-top mb-4 shadow rounded"/>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
